import React, { useEffect } from "react";
import "./style.css";

import script from "./script";

export default function BenPong() {
  useEffect(() => script(), []);
  return (
    <>
      <div class="board">
        <div class="ball">
          <div class="ball_effect"></div>
        </div>
        <div class="paddle_1 paddle"></div>
        <div class="paddle_2  paddle"></div>
        <h1 class="player_1_score">0</h1>
        <h1 class="player_2_score">0</h1>
        <h1 class="message">Press Enter to Play Pong</h1>
      </div>
    </>
  );
}
