import React from "react";

export default function Header() {
  return (
    <div
      class="container-fluid bg-breadcrumb"
      style={{
        backgroundImage: `linear-gradient(rgba(255, 204, 0, 0.1), rgba(255, 204, 0, 0.1)), url(${require("../../assets/images/about_header.jpg")})`,
      }}
    >
      <div class="container text-center py-5" style={{ maxWidth: "900px" }}>
        <h3 class="text-white display-3 mb-4">About Us</h3>
      </div>
    </div>
  );
}
