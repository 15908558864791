import React, { useState } from "react";
import Header from "./Header";

import styles from "./styles.module.css";

import { v4 as uuid } from "uuid";

export default function Fleet() {
  return (
    <div>
      <Header />
      <div className={styles.container}>
      <AircraftContainer
          aircraftType={"MD11"}
          title="The McDonnell Douglas MD-11F"
          desc="A versatile and long-range freighter aircraft known for its reliability in cargo operations worldwide."
          liveries={["world"]}
        />
        <AircraftContainer
          aircraftType={"DC10"}
          title="The McDonnell Douglas DC-10F"
          desc="A robust cargo aircraft valued for its large payload capacity and wide-body design, widely used in freight transport."
          liveries={["generic"]}
        />
        <AircraftContainer
          aircraftType={"B742"}
          title="The Boeing 747-200"
          desc="A classic jumbo jet renowned for its range, high payload capacity, and iconic humpback design."
          liveries={["generic"]}
        />
        <AircraftContainer
          aircraftType={"B744"}
          title="The Boeing 747-400"
          desc="A highly successful long-range jumbo jet, known for its extended range, fuel efficiency, and technological advancements over earlier 747 models."
          liveries={["generic"]}
        />
        <AircraftContainer
          aircraftType={"B748"}
          title="The Boeing 747-800"
          desc="The latest variant of the iconic jumbo jet, featuring improved fuel efficiency, greater range, and advanced aerodynamics."
          liveries={["generic"]}
        />
      </div>
    </div>
  );
}

function AircraftContainer({ aircraftType, liveries = [], title, desc }) {
  const [livery, setLivery] = useState(liveries[0]);
  return (
    <div className={styles.aircraftContainer}>
      <h1>{title}</h1>
      <p>{desc}</p>
      <div className={styles.liverySelector}>
        {liveries.map((l) => {
          return (
            <button
              key={uuid()}
              className={livery === l ? styles.active : ""}
              onClick={() => setLivery(l)}
            >
              <img
                src={require(`../../assets/aircraft/${l}.png`)}
                alt={l}
                title={l}
              />
            </button>
          );
        })}
      </div>
      <img
        src={require(`../../assets/aircraft/${aircraftType}_${livery}.png`)}
        className={styles.aircraftImage}
        alt={`$${livery} {aircraftType}`}
      />
    </div>
  );
}
