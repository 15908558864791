import React, { useEffect, useState } from "react";
import styles from "./stats.module.css";

import ky from "ky";

export default function Stats() {
  const [pirepCount, setPirepCount] = useState(0);
  const [hoursFlown, setHoursFlown] = useState(3500); // in seconds
  const [members, setMembers] = useState(0);

  useEffect(() => {
    (async () => {
      const data = await ky.get("https://api.wavacva.xyz/stats").json();
      console.log(data);
      setPirepCount(data.pirepCount);
      setHoursFlown(data.totalFlightTime);
      setMembers(data.activePilots);
    })();
  }, []);
  return (
    <div className="container-fluid bg-light service py-5">
      <div className="container">
        <div
          className="mx-auto text-center"
          style={{ maxWidth: "900px" }}
        ></div>
        <div className={styles.statsContainer}>
          <div className={styles.statContainer}>
            <h5>Total PIREPs filed:</h5>
            <h2>{pirepCount}</h2>
          </div>
          <div className={styles.statContainer}>
            <h5>Total Time Flown:</h5>
            <h2>{formatSecondsToHoursMins(hoursFlown)}</h2>
          </div>
          <div className={styles.statContainer}>
            <h5>Active Pilots:</h5>
            <h2>{members}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

function formatSecondsToHoursMins(time) {
  const hours = time / 60 / 60;
  const wholeHours = Math.floor(hours);
  const minutes = Math.round((hours % 1) * 60);

  console.log(wholeHours, minutes);
  return `${wholeHours.toLocaleString(`en-US`, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${minutes.toLocaleString(`en-US`, { minimumIntegerDigits: 2 })}`;
}
