import React from "react";
import "./Topbar.css";

export default function Topbar() {
  return (
    <div class="container-fluid bg-primary px-5 d-none d-lg-block">
      <div class="row gx-0">
        <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
          <div
            class="d-inline-flex align-items-center"
            style={{ height: "45px" }}
          ></div>
        </div>
        <div class="col-lg-4 text-center text-lg-end">
          <div
            class="d-inline-flex align-items-center"
            style={{ height: "45px" }}
          ></div>
        </div>
      </div>
    </div>
  );
}
