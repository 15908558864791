import React from "react";

export default function About() {
  return (
    <div class="container-fluid about py-5">
      <div class="container py-5">
        <div class="row g-5 align-items-center">
          <div class="col-lg-5">
            <div
              class="h-100"
              style={{
                border: "50px solid",
                borderColor:
                  "transparent var(--bs-primary) transparent var(--bs-primary)",
                padding: ".5rem",
              }}
            >
              <img
                src={require("../../assets/images/about_image.jpg")}
                class="img-fluid w-100 h-100"
                alt=""
              />
            </div>
          </div>
          <div
            class="col-lg-7"
            style={{
              background:
                "linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(img/about-img-1.png)",
            }}
          >
            <h5 class="section-about-title pe-3">About Us</h5>
            <h1 class="mb-4" style={{ color: "#000" }}>
              Worldwide Deliveries;{" "}
              <span class="text-primary">Unmatched Reliability.</span>
            </h1>
            <p class="mb-4">
              WAVA Cargo strives to create an enjoyable environment for all
              pilots to come and fly together. With the unique historical
              atmosphere and legacy the World Airways brand brings, we have an
              opportunity to recreate a glimpse of a golden era of aviation
              within this organization. WAVA Cargo will also have an unique rank
              system that allows our pilots to have a dynamic and long-term
              timeline before achieving our top rank, giving them something to
              work for within our VA for a much longer time than the traditional
              rank system may allow. This all culminates in the ultimate goal of
              global charter discretion - allowing for complete freedom of
              destination for a pilot.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
