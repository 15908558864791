import React from "react";
import ReactDOM from "react-dom/client";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import App from "./App";
import About from "./pages/About";
import Fleet from "./pages/Fleet";
import Home from "./pages/Home";
import Hubs from "./pages/Hubs";
import NotFound from "./pages/NotFound";
import Ranks from "./pages/Ranks";
import Routes from "./pages/Routes";

import Pong from "./pages/BenPong";

const router = createBrowserRouter([
  {
    path: "/pong",
    element: <Pong />,
  },
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/about", element: <About /> },
      { path: "/ranks", element: <Ranks /> },
      { path: "/routes", element: <Routes /> },
      { path: "/fleet", element: <Fleet /> },
      { path: "/hubs", element: <Hubs /> },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
