import React from "react";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div class="container-fluid footer py-5">
      <div class="container py-5">
        <div class="row g-5" style={{ justifyContent: "center" }}>
          <div class="col-md-6 col-lg-6 col-xl-3">
            <div class="footer-item d-flex flex-column">
              <h4 class="mb-4" style={{ color: "var(--bs-primary)" }}>
                NOTICE:
              </h4>
              <div>
                World Airways Cargo VA is strictly for the Infinite Flight
                platform and is not endorsed or affiliated with Global Aviation
                Holdings (World Airways' parent company) or its subsidiaries.
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-3">
            <div class="footer-item d-flex flex-column">
              <a href="https://community.infiniteflight.com">
                <i class="fas fa-angle-right me-2"></i> IFC Thread
              </a>
              <a href="https://ifvarb.com">
                <i class="fas fa-angle-right me-2"></i> IFVARB
              </a>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-3">
            <div class="footer-item d-flex flex-column">
              <Link to="/fleet">
                <i class="fas fa-angle-right me-2"></i> Fleet
              </Link>
              <Link to="/routes">
                <i class="fas fa-angle-right me-2"></i> Routes
              </Link>
              <Link to="/fleet">
                <i class="fas fa-angle-right me-2"></i> Fleet
              </Link>
              <Link to="/hubs">
                <i class="fas fa-angle-right me-2"></i> Hubs
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
