import React, { useState } from "react";
import Header from "./Header";

import styles from "./styles.module.css";

import { v4 as uuid } from "uuid";

const HUBS = {
  "North America": [
    {
      icao: "KJFK",
      name: "John F. Kennedy International",
      desc: "John F. Kennedy International Airport (KJFK) is a major international gateway in New York, serving World Airways with routes connecting North America to Europe, Asia, and beyond.",
    },
    {
      icao: "KSTL",
      name: "St. Louis Lambert International",
      desc: "St. Louis Lambert International Airport (KSTL) serves as a regional hub for World Airways, providing access to key domestic and international destinations.",
    },
    {
      icao: "KSFO",
      name: "San Francisco International",
      desc: "San Francisco International Airport (KSFO) is a strategic West Coast hub for World Airways, offering transpacific routes and connections across the U.S.",
    },
    {
      icao: "PHNL",
      name: "Daniel K. Inouye International",
      desc: "Daniel K. Inouye International Airport (PHNL) in Honolulu serves as a Pacific hub for World Airways, connecting North America with Asia and Oceania.",
    },
    {
      icao: "TNCM",
      name: "Princess Juliana International",
      desc: "Princess Juliana International Airport (TNCM) in St. Maarten is a key Caribbean destination for World Airways, offering routes across the Americas and Europe.",
    },
  ],
  "South America": [
    {
      icao: "SAEZ",
      name: "Ministro Pistarini International",
      desc: "Ministro Pistarini International Airport (SAEZ) in Buenos Aires connects World Airways passengers to major South American and international destinations.",
    },
  ],
  Africa: [
    {
      icao: "HECA",
      name: "Cairo International",
      desc: "Cairo International Airport (HECA) is a major African hub for World Airways, providing access to destinations across Africa, Europe, and the Middle East.",
    },
    {
      icao: "FAOR",
      name: "O.R. Tambo International",
      desc: "O.R. Tambo International Airport (FAOR) in Johannesburg is a critical African hub for World Airways, offering connections to Europe, Asia, and the Americas.",
    },
  ],
  Europe: [
    {
      icao: "EGBB",
      name: "Birmingham",
      desc: "Birmingham Airport (EGBB) is a key European stopover for World Airways, linking the UK to long-haul routes across the globe.",
    },
    {
      icao: "EPWA",
      name: "Warsaw Chopin",
      desc: "Warsaw Chopin Airport (EPWA) is a central European hub for World Airways, offering flights connecting Europe with Asia and the Americas.",
    },
  ],
  Asia: [
    {
      icao: "OMDB",
      name: "Dubai International",
      desc: "Dubai International Airport (OMDB) is a key Middle Eastern hub for World Airways, connecting passengers to Europe, Asia, and Africa.",
    },
    {
      icao: "RKSI",
      name: "Incheon International",
      desc: "Incheon International Airport (RKSI) in Seoul is a major East Asian hub for World Airways, facilitating connections across Asia, Europe, and North America.",
    },
    {
      icao: "RJBB",
      name: "Kansai International",
      desc: "Kansai International Airport (RJBB) in Osaka is a critical hub for World Airways in Japan, linking the country to destinations across the globe.",
    },
  ],
  Oceania: [
    {
      icao: "YSSY",
      name: "Sydney Kingsford Smith",
      desc: "Sydney Kingsford Smith Airport (YSSY) is a key Australian hub for World Airways, offering routes to Asia, North America, and beyond.",
    },
  ],
};

export default function Hubs() {
  const [region, setRegion] = useState("North America");
  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.regionSelector}>
          {Object.keys(HUBS).map((r) => {
            return (
              <button
                key={uuid()}
                className={`${r === region ? styles.active : ""}`}
                onClick={() => setRegion(r)}
              >
                {r}
              </button>
            );
          })}
        </div>
        <div className={styles.hubs}>
          {HUBS[region].map((hub) => {
            return (
              <HubContainer
                key={uuid()}
                name={hub.name}
                icao={hub.icao}
                desc={hub.desc}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

function HubContainer({ name, icao, desc }) {
  return (
    <div
      className={styles.hubContainer}
      style={{
        backgroundImage: `url(${require(`../../assets/hubs/${icao}.png`)})`,
      }}
    >
      {/* <div className="headings">
        <h3>{icao}</h3>
        <h1>{name}</h1>
      </div> */}
      <div className={styles.infoSlider}>
        <p>{desc}</p>
      </div>
    </div>
  );
}
