import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "var(--bs-primary)",
      }}
    >
      <h1>404</h1>
      <h2>This page does not exist!</h2>
      <Link to="/">Return to home</Link>
    </div>
  );
}
