import React from "react";
import Carousel from "./Carousel";
import FromOurCEO from "./FromOurCEO";
import Stats from "./Stats";
import WhatWeOffer from "./WhatWeOffer";

export default function Home() {
  return (
    <>
      <Carousel />
      <Stats />
      <FromOurCEO />
      <WhatWeOffer />
    </>
  );
}
