import React from "react";

export default function Carousel() {
  return (
    <div className="carousel-header">
      <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
        <ol className="carousel-indicators">
          <li
            data-bs-target="#carouselId"
            data-bs-slide-to="0"
            className="active"
          ></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
          <CarouselItem
            image={require("../../assets/images/carousel_1.jpg")}
            title={"Worldwide Deliveries; Unmatched Reliability"}
            subtitle={"- World Airlines Cargo Virtual -"}
            buttonText={"APPLY NOW"}
            buttonHref={"/apply"}
            active
          />
          <CarouselItem
            image={require("../../assets/images/carousel_2.jpg")}
            subtitle={"THE GOLDEN ERA OF AVIATION"}
            title={"The Boeing 747 Family"}
            buttonText={"Available at WAVA Cargo"}
            buttonHref={"/fleet"}
          />
          <CarouselItem
            image={require("../../assets/images/carousel_3.jpg")}
            subtitle={"- OUR FLAGSHIP AIRCRAFT -"}
            title={"The McDonnell Douglas MD-11F"}
            buttonText={"CHECK OUT OUR FLEET"}
            buttonHref={"/fleet"}
          />
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon btn bg-primary"
            aria-hidden="false"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon btn bg-primary"
            aria-hidden="false"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

function CarouselItem({
  image,
  subtitle,
  title,
  description = "",
  buttonText,
  buttonHref,
  active,
}) {
  return (
    <div className={`carousel-item ${active ? "active" : ""}`}>
      <img src={image} className="img-fluid" alt="Image" />
      <div className="carousel-caption">
        <div className="p-3" style={{ maxWidth: "900px" }}>
          <h4
            className="text-white text-uppercase fw-bold mb-4"
            style={{ letterSpacing: "3px" }}
          >
            {subtitle}
          </h4>
          <h1 className="display-2 text-capitalize text-white mb-4">{title}</h1>
          <p className="mb-5 fs-5">{description}</p>
          <div className="d-flex align-items-center justify-content-center">
            {buttonHref ? (
              <a
                className="btn-hover-bg btn btn-primary rounded-pill text-white py-3 px-5"
                href={buttonHref}
              >
                {buttonText}
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
