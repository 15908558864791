// import "./style.css";

import Footer from "./components/Footer";

import Navbar from "./components/Navbar";
import Topbar from "./components/Topbar";

import { Outlet } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Topbar />
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
