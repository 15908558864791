import React from "react";

export default function WhatWeOffer() {
  return (
    <div className="container-fluid bg-light service py-5">
      <div className="container py-5">
        <div className="mx-auto text-center mb-5" style={{ maxWidth: "900px" }}>
          <h1 className="mb-0">What we offer:</h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-6">
            <div className="row g-4">
              <CardContainerRTL
                title={"IFVARB Approval In Progress"}
                description="We are in the process of and approval by the Infinite Flight Virtual Airline Regulatory Board to ensuring a high quality of service for our pilots and members of the community."
                icon={<i className="fa fa-globe fa-4x text-primary"></i>}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row g-4">
              <CardContainerLTR
                title={"An extensive route network"}
                description="With over 1,500 routes across 16 of our hubs, feel free to explore and get a taste of each corner of the Infinite Flight world."
                icon={<i className="fa fa-globe fa-4x text-primary"></i>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Icon on the right
function CardContainerLTR({ title, description, icon }) {
  return (
    <div className="col-12">
      <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 ps-0">
        <div className="service-icon p-4">{icon}</div>
        <div className="service-content">
          <h5 className="mb-4">{title}</h5>
          <p className="mb-0">{description}</p>
        </div>
      </div>
    </div>
  );
}

function CardContainerRTL({ title, description, icon }) {
  return (
    <div className="col-12">
      <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 pe-0">
        <div className="service-content text-end">
          <h5 className="mb-4">{title}</h5>
          <p className="mb-0">{description}</p>
        </div>
        <div className="service-icon p-4">{icon}</div>
      </div>
    </div>
  );
}
