import React, { cloneElement, useEffect, useState } from "react";
import {
  LayerGroup,
  MapContainer as MC,
  Marker,
  Polyline,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import Header from "./Header";
import styles from "./style.module.css";

import hubIcon from "./hubIcon";

import { v4 as uuid } from "uuid";

import ky from "ky";

export default function Routes() {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [view, setView] = useState(0); // 0 = map, 1 = table

  useEffect(() => {
    (async () => {
      try {
        const routes = await ky.get("https://api.wavacva.xyz/routes").json();
        setData(routes);
      } catch (err) {
        setError(err);
      }
    })();
  }, []);
  return (
    <>
      <Header />
      <ViewSelector view={view} setView={setView} />
      {view === 1 ? (
        <TableContainer error={error} data={data} />
      ) : (
        <MapContainer error={error} data={data} />
      )}
    </>
  );
}

function ViewSelector({ view, setView }) {
  return (
    <div className={styles.viewselector}>
      <button
        className={`${view === 0 ? styles.active : ""}`}
        onClick={() => setView(0)}
      >
        Map
      </button>
      <button
        className={`${view === 1 ? styles.active : ""}`}
        onClick={() => setView(1)}
      >
        Table
      </button>
    </div>
  );
}

function TableContainer({ data, error }) {
  const [flightNumber, setFlightNumber] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");

  const filterRoutes = () => {
    const toReturn = [];
    for (const origin of Object.values(data)) {
      if (origin.icao.startsWith(departure)) {
        const route = origin.routes.find(
          (route) =>
            route.arrival.icao.startsWith(arrival) &&
            route.flightNumber.startsWith(flightNumber)
        );
        if (route) toReturn.push({ origin: origin.icao, route: route });
      }
    }
    return toReturn;
  };

  return (
    <div className={styles.tablecontainer}>
      <div className={styles.filters}>
        <div>
          <label htmlFor="flightnumber">Flight number</label>
          <input
            type="text"
            name="flightnumber"
            value={flightNumber}
            onChange={(e) => setFlightNumber(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="departure">Departure ICAO</label>
          <input
            type="text"
            name="departure"
            value={departure}
            maxLength={4}
            onChange={(e) => setDeparture(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="arrival">Arrival ICAO</label>
          <input
            type="text"
            name="arrival"
            value={arrival}
            maxLength={4}
            onChange={(e) => setArrival(e.target.value)}
          />
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <th>Flight Number</th>
          <th>Departure ICAO</th>
          <th>Arrival ICAO</th>
        </thead>
        <tbody>
          {filterRoutes().map((route) => {
            return (
              <tr>
                <td>{route.route.flightNumber}</td>
                <td>{route.origin}</td>
                <td>{route.route.arrival.icao}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function MapContainer({ data, error }) {
  return (
    <div className={styles.mapcontainer}>
      <ul className={styles.mapkey}>
        <li>
          <img src={require("../../assets/hubicon.png")} alt="hub map icon" />
          denotes an airport which serves as a hub.
        </li>
      </ul>
      {Object.entries(data).length === 0 && error == null ? (
        <MapLoading />
      ) : null}
      {error != null ? <MapError message={error.message} /> : null}
      <Map data={data} />
    </div>
  );
}

function MapError({ message }) {
  return (
    <div className={styles.maperror}>
      <h1>An error has occurred!</h1>
      <p>{message}</p>
    </div>
  );
}

function MapLoading() {
  return (
    <div className={styles.maploading}>
      <h1>Loading map...</h1>
    </div>
  );
}

function Map({ data }) {
  return (
    <MC
      center={[50.733334, 7.1]}
      zoom={5}
      scrollWheelZoom={false}
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: "3rem",
        zIndex: 5,
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <RoutesLayer data={data} />
    </MC>
  );
}

function RoutesLayer({ data }) {
  const [selected, setSelected] = useState(null);

  const OnClickElements = ({ tooltipText, originLocation, routes }) => {
    return (
      <>
        <Tooltip content={tooltipText} permanent={true} />
        {routes.map((route) => {
          return (
            <Polyline
              key={uuid()}
              positions={[originLocation, route.arrival.location]}
              weight={1.5}
            />
          );
        })}
      </>
    );
  };

  const MarkerToPlot = ({ airport }) => {
    const marker = (
      <Marker
        position={airport.location}
        key={uuid()}
        eventHandlers={{
          click: () => {
            setSelected(airport.icao);
          },
        }}
      >
        {selected === airport.icao ? (
          <OnClickElements
            tooltipText={airport.icao}
            originLocation={airport.location}
            routes={airport.routes}
          />
        ) : null}
      </Marker>
    );
    if (airport.isHub) {
      return cloneElement(marker, { icon: hubIcon });
    } else {
      return marker;
    }
  };

  return (
    <LayerGroup>
      {Object.values(data).map((airport) => {
        return <MarkerToPlot key={uuid()} airport={airport} />;
      })}
    </LayerGroup>
  );
}
