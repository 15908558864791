import { useState } from 'react'

export default function useIFCProfile(username) {
    const [userData, setUserData] = useState({})


    // Hack fix. Come back to this later.
    // useEffect(() => {
    //     (async() => {
    //         const response = await ky.get(`https://community.infiniteflight.com/u/${username}.json`, {headers: {'origin': 'https://safiyurrahman.netlify.app'}}).json()
    //         setUserData({...response.user, profilepic: `https://sea1.discourse-cdn.com/infiniteflight${response.user.avatar_template.replace("{size}", "512")}`})
    //     })()
    // }, [])
  return {profilepic: `https://sea1.discourse-cdn.com/infiniteflight/user_avatar/community.infiniteflight.com/${username}/288/1409095_2.png`}
}
