import React, { useState } from "react";

import useIFCProfile from "../../hooks/useIFCProfile";

export default function FromOurCEO() {
  const benIFC = useIFCProfile("ben.r");

  // Easter egg stuff
  const [clicks, setClicks] = useState(0);
  const onProfilePicClick = (e) => {
    switch (clicks) {
      case 0:
        alert("Click me again");
        break;
      case 1:
        alert("again");
        break;
      case 2:
        alert("CLICK ME AGAIN!!!");
        break;
      case 3:
        window.location.replace("/pong");
        break;
      default:
        break;
    }
    setClicks((x) => x + 1);
  };
  console.log(benIFC);
  return (
    <div className="container-fluid about py-5">
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-5">
            <div
              className="h-100"
              style={{
                border: "50px solid",
                borderColor:
                  "transparent var(--bs-primary) transparent var(--bs-primary)",
                padding: ".5rem",
              }}
            >
              <img
                src={benIFC.profilepic}
                className="img-fluid w-100 h-100"
                alt=""
                onClick={onProfilePicClick}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div
            className="col-lg-7"
            style={{
              background:
                "linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(/img/about-img-1.png)",
            }}
          >
            <h5 className="section-about-title pe-3">From our CEO</h5>
            <h1 className="mb-4" style={{ color: "#000" }}>
              Welcome to{" "}
              <span className="text-primary">World Airways Cargo Virtual</span>!
            </h1>
            <p className="mb-4">
              At WAVA Cargo Virtual, we strive to create an enjoyable
              environment for all pilots to come and fly together. This is so
              that any pilot can come and join our VA and have a different taste
              than a regular Cargo VA. A place to experience worldwide
              deliveries with unmatched reliability.
            </p>
            <a
              href="https://community.infiniteflight.com/u/ben.r/"
              className="mb-4"
            >
              - Ben R, WAVA Founder and CEO
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
